<template>
  <div class="vg_wrapper" v-sticky="{ top: 100, parent: '.el-scrollbar__wrap' }">
    <div class="tSearch" style="background: #ffffff; border-radius: 2px">
      <div class="vd_search_group">
        <el-form :inline="true" :model="searchForm" ref="searchForm" @keyup.enter.native="getNow()" label-width="120px">
          <el-row>
            <el-col :md="22">
              <el-row>
                <el-col :md="8">
                  <el-form-item label="材料采购号:">
                    {{ transferForm.modr_no }}
                  </el-form-item>
                </el-col>
                <el-col :md="8">
                  <el-form-item label="订单类型:">
                    <el-select v-model="searchForm.puca_type" filterable placeholder="请选择订单类型" clearable size="small">
                      <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :md="8">
                  <el-form-item label="要求交货日期:">
                    {{ transferForm.deli_date | formatDate }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :md="8">
                  <el-form-item label="成品采购号:">
                    <el-input size="small" v-model.trim="searchForm.podr_no" clearable placeholder="请填写成品采购号"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="8">
                  <el-form-item label="收货方简称:">
                    <el-input size="small" v-model.trim="searchForm.supp_abbr" clearable placeholder="请填写收货方简称"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="8">
                  <el-form-item label="合同日期:">
                    {{ transferForm.puca_date | formatDate }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <div class="formItem">
                  <el-col :md="24" class="vg_mb_8">
                    <el-form-item label="订单备注:">
                      <el-input type="textarea" disabled :rows="6" v-model="transferForm.orderRemarks"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :md="24">
                    <el-form-item label="变更内容:">
                      <el-input type="textarea" disabled :row="3" v-model="transferForm.change_Content"></el-input>
                    </el-form-item>
                  </el-col>
                </div>
              </el-row>
            </el-col>
            <el-col :md="1">
              <span v-if="transferForm.pdf_status === 0" class="vd_cd81e06">未打印</span>
              <span v-if="transferForm.pdf_status === 1" class="vd_c4dbf2a">已打印</span>
            </el-col>
            <!--						<el-col :md="8">-->
            <!--							<el-form-item label="订单来源:">-->
            <!--								<el-select v-model="searchForm.puca_source" filterable placeholder="请选择订单来源" clearable size="small">-->
            <!--									<el-option-->
            <!--											v-for="item in sourceList"-->
            <!--											:key="item.value"-->
            <!--											:label="item.label"-->
            <!--											:value="item.value">-->
            <!--									</el-option>-->
            <!--								</el-select>-->
            <!--							</el-form-item>-->
            <!--						</el-col>-->
            <!--					</el-row>-->
            <!--					<el-row>-->
            <!--						<el-col :md="8">-->
            <!--							<el-form-item label="供应商简称:">-->
            <!--								<el-input size="small" v-model.trim="searchForm.supp_abbr" clearable placeholder="请填写供应商简称"></el-input>-->
            <!--							</el-form-item>-->
            <!--						</el-col>-->
            <!--						<el-col :md="8">-->
            <!--							<el-form-item label="采购经办人:">-->
            <!--								<el-input size="small" v-model.trim="searchForm.puca_stff_name" clearable placeholder="请填写采购经办人"></el-input>-->
            <!--							</el-form-item>-->
            <!--						</el-col>-->
            <!-- <el-col :md="8">
							<el-form-item  label="下发状态:">
								<el-select v-model="searchForm.puca_status" filterable placeholder="请选择下发状态" clearable size="small">
									<el-option
											v-for="item in statusList"
											:key="item.value"
											:label="item.label"
											:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col> -->
          </el-row>
        </el-form>
        <div class="vd_button_group vg_mtb_16">
          <el-button size="small" type="primary" icon="el-icon-search" @click="getPucas()">查询</el-button>
          <el-button size="small" type="warning" icon="el-icon-s-check" class="vd_export" @click="PrintPucaPdfByModrId()"
            >打印预览</el-button
          >
          <el-button size="small" type="success" icon="el-icon-receiving" class="vd_export" @click="exportToExcel">导出Excel</el-button>
          <!-- <el-button size="small" type="info" icon="el-icon-bottom" class="vd_export" @click="exportPucaPdfBy()">下载材料订单</el-button> -->
        </div>
      </div>
      <div class="vd_dash"></div>
      <div>
        <el-link type="warning" :underline="false">
          <i class="el-icon-warning"></i>
          <span>Tips:任何操作不可撤销，请核对后再进行操作!</span>
        </el-link>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button
          v-if="userInfo.acct_id === 1"
          :disabled="invalidFlag"
          size="mini"
          type="danger"
          icon="el-icon-error"
          @click="makeInvalidOrder()"
          >失效</el-button
        >
        <el-button v-if="userInfo.acct_id === 1" type="success" icon="el-icon-circle-check" plain size="mini" @click="issue()"
          >下发</el-button
        >
        <!--				 <el-button size="mini" plain type="primary" icon="el-icon-printer" @click="makeInvalidOrder()"></el-button>-->
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            id="matrExcel"
            v-if="showFlag"
            stripe
            ref="multiTable"
            v-loading="loadingFlag"
            :data="tableData"
            border
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column v-show="isExporting" label="材料采购号" prop="puca_type" min-width="150" align="center">
              <template>
                <span>{{ transferForm.modr_no }}</span>
              </template>
            </el-table-column>
            <el-table-column label="订单类型" prop="puca_type" min-width="70" align="center">
              <template v-slot="scope">
                <el-link :underline="false" :type="scope.row.puca_type === 0 ? 'primary' : 'success'">{{
                  scope.row.puca_type === 0 ? '送货单' : '补货单'
                }}</el-link> </template
              >=
            </el-table-column>
            <el-table-column label="下发状态" prop="puca_status" width="80" align="center">
              <template v-slot="scope">
                <el-tag v-if="scope.row.puca_status === 0" type="info" size="small">未下发</el-tag>
                <el-tag v-else-if="scope.row.puca_status === 1" size="small">已下发</el-tag>
                <el-tag v-else-if="scope.row.puca_status === 2" type="success" size="small">已完结</el-tag>
                <el-tag v-else-if="scope.row.puca_status === 3" type="danger" size="small">已失效</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="成品采购号" prop="podr_no" width="100">
              <template v-slot="scope">
                <span v-if="scope.row.podr_no">{{ scope.row.podr_no }}</span>
                <span v-else style="color: #ccc"><i class="el-icon-time" /> 暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="收货方简称" prop="supp_abbr" width="100">
              <template v-slot="scope">
                <span v-if="scope.row.supp_abbr">{{ scope.row.supp_abbr }}</span>
                <span v-else style="color: #ccc"><i class="el-icon-time" /> 暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="材料名称" prop="mtrb_name" align="center" width="120" show-overflow-tooltip />
            <el-table-column label="材料规格" prop="mtrb_spec" align="center" min-width="120">
              <template v-slot="scope">
                <el-popover placement="bottom" width="200" trigger="hover">
                  <span>{{ scope.row.mtrb_spec }}</span>
                  <el-row slot="reference">
                    <el-col class="vg_textell">{{ scope.row.mtrb_spec }}</el-col>
                  </el-row>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="单位" prop="puca_mtrb_unit" align="center" min-width="60">
              <template v-slot="scope">
                <span v-if="scope.row.puca_mtrb_unit">{{ scope.row.puca_mtrb_unit }}</span>
                <span v-else style="color: #ccc"><i class="el-icon-time" /> 暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="订单数量" prop="puca_mtrb_num" align="center" min-width="100">
              <template v-slot="scope">
                <span v-show="isExporting">{{ tableData[scope.$index].puca_mtrb_num }}</span>
                <el-input
                  v-show="!isExporting"
                  :disabled="scope.row.clickChangeFlag"
                  @change="pucaMtrbTotalChange(scope.$index, 1)"
                  v-model="tableData[scope.$index].puca_mtrb_num"
                  @input="tableData[scope.$index].puca_mtrb_num = helper.keepTNum1(tableData[scope.$index].puca_mtrb_num)"
                  maxlength="30"
                  show-word-limit
                  placeholder="暂无数量"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="备品数量" prop="puca_mtrb_bnum" align="center" min-width="80"></el-table-column>
            <!-- <el-table-column label="变更数量" prop="puca_mtrb_cnum" align="center" min-width="100">
							<template v-slot="scope">
                <el-input :disabled="scope.row.clickChangeFlag" v-model="tableData[scope.$index].puca_mtrb_cnum" @input="tableData[scope.$index].puca_mtrb_cnum = helper.keepTNum1(tableData[scope.$index].puca_mtrb_cnum)" maxlength="30" show-word-limit
                  placeholder="暂无变更数量"></el-input>
							</template>
						</el-table-column> -->
            <el-table-column label="单价" prop="puca_mtrb_price" align="center" min-width="100">
              <template v-slot="scope">
                <span v-show="isExporting">{{ tableData[scope.$index].puca_mtrb_price }}</span>
                <el-input
                  v-show="!isExporting"
                  :disabled="scope.row.clickChangeFlag"
                  @change="pucaMtrbTotalChange(scope.$index, 2)"
                  v-model="tableData[scope.$index].puca_mtrb_price"
                  @input="tableData[scope.$index].puca_mtrb_price = helper.keepTNum1(tableData[scope.$index].puca_mtrb_price)"
                  maxlength="30"
                  show-word-limit
                  placeholder="暂无单价"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="金额" prop="puca_mtrb_total" align="center" min-width="100">
              <template v-slot="scope">
                <span v-show="isExporting">{{ tableData[scope.$index].puca_mtrb_total }}</span>
                <el-input
                  v-show="!isExporting"
                  :disabled="scope.row.clickReissueFlag"
                  v-model="tableData[scope.$index].puca_mtrb_total"
                  @input="tableData[scope.$index].puca_mtrb_total = helper.keepTNum1(tableData[scope.$index].puca_mtrb_total)"
                  maxlength="30"
                  show-word-limit
                  placeholder="暂无金额"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="物流单号" prop="locs_no" min-width="80" align="center">
              <template v-slot="scope">
                <el-link class="vg_cursor" v-if="scope.row.locs_no" @click="showDialog(scope.row.locs_no)">
                  <div class="vg_hover_icon">
                    <span style="margin-left: 15px">{{ scope.row.locs_no }}</span>
                  </div>
                </el-link>
                <span v-else style="color: #ccc"><i class="el-icon-time" /> 暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="司机联系方式" prop="driv_phone" align="center" width="120">
              <template v-slot="scope">
                <div v-if="scope.row.driv_phone">
                  <el-row>
                    <el-col>
                      <span><i class="el-icon-user-solid" /></span>
                      <span> {{ scope.row.driv_name }} </span>
                    </el-col>
                    <el-col>
                      <span><i class="el-icon-phone-outline" /></span>
                      <span> {{ scope.row.driv_phone }}</span>
                    </el-col>
                  </el-row>
                </div>
                <span v-else-if="scope.row.locs_no" style="color: #e01515; font-size: 22px">/</span>
                <span v-else style="color: #ccc"><i class="el-icon-time" /> 暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="备注" align="center" width="240px">
              <template v-slot="scope">
                <div v-if="userInfo.acct_id === 1 && !isExporting">
                  <div v-if="scope.row.puca_mtrb_remark" style="display: flex">
                    <el-input class="vg_cursor" v-model="scope.row.puca_mtrb_remark" disabled>
                      <template slot="append">
                        <el-popover placement="bottom" width="600" @show="textEnlargementShow(scope.$index)" trigger="click">
                          <textEnlargement
                            ref="textEnlargement"
                            :disabledFlag="true"
                            @textEnlargementChange="textEnlargementChange"
                            :textEnlargementString="textEnlargementString"
                          ></textEnlargement>
                          <span slot="reference"><el-link type="info">...</el-link></span>
                        </el-popover>
                      </template>
                    </el-input>
                    <el-link
                      v-if="userInfo.acct_id === 1"
                      :disabled="scope.row.remarkFlag"
                      type="primary"
                      style="width: 100px"
                      class="vg_cursor"
                      @click="openRemark(scope.row)"
                      >重新编辑</el-link
                    >
                  </div>
                  <el-link :disabled="scope.row.remarkFlag" v-else type="primary" class="vg_cursor" @click="openRemark(scope.row)"
                    >编辑</el-link
                  >
                </div>
                <div v-else>
                  <span v-if="scope.row.puca_mtrb_remark">{{ scope.row.puca_mtrb_remark }}</span>
                  <span v-else style="color: #ccc">暂无</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="240">
              <template v-slot="scope">
                <div v-if="scope.row.clickReissueFlag && scope.row.clickChangeFlag && !isExporting" class="changeRecords">
                  <el-button
                    class="vg_mr_8"
                    v-if="userInfo.acct_id === 1 && scope.row.puca_type === 1"
                    :disabled="scope.row.changeFlag"
                    plain
                    size="mini"
                    type="warning"
                    @click="changeNumCopy(scope.$index)"
                    >变更</el-button
                  >
                  <el-button
                    class="vg_mr_8"
                    v-if="userInfo.acct_id === 1 && scope.row.puca_type === 0"
                    :disabled="scope.row.reissueFlag"
                    plain
                    size="mini"
                    type="warning"
                    @click="reissueCopy(scope.$index)"
                    >补发</el-button
                  >
                  <el-badge :is-dot="tableData[scope.$index].is_update === 1">
                    <el-button class="vd_posi_c" plain size="mini" type="info" @click="changeRecord(scope.$index, scope)"
                      >变更记录
                      <span class="vd_posi" v-show="tableData[scope.$index].numchaneg > 0"></span>
                    </el-button>
                  </el-badge>
                </div>
                <div v-else>
                  <el-button v-if="!isExporting" plain size="mini" type="success" @click="sublitRow(scope.row)">保存</el-button>
                  <span v-else></span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row> </el-row>
      <!--下发提示框-->
      <el-dialog :visible.sync="issueDialogVisible" top="45vh" center width="40%">
        <span slot="title" style="font-size: 20px">系统提示</span>
        <div style="text-align: center">
          <span style="font-size: 18px">是否下发选中订单</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" size="mini" @click="issueDialogVisible = false">否</el-button>
          <el-button type="primary" size="mini" @click="confirmIssue()">是</el-button>
        </span>
      </el-dialog>
      <!--失效提示框-->
      <el-dialog :visible.sync="invalidDialogVisible" top="45vh" center width="40%">
        <span slot="title" style="font-size: 20px">系统提示</span>
        <div style="text-align: center">
          <span style="font-size: 18px">是否对此订单失效</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" size="mini" @click="invalidDialogVisible = false">否</el-button>
          <el-button type="primary" size="mini" @click="confirmMakeInvalidOrder()">是</el-button>
        </span>
      </el-dialog>
      <!--变更提示框-->
      <el-dialog :visible.sync="changeNumDialogVisible" top="45vh" center width="40%">
        <span slot="title" style="font-size: 20px">系统提示</span>
        <div style="text-align: center">
          <span style="font-size: 18px">是否已和外销确认数量</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" size="mini" @click="changeNumDialogVisible = false">尚未</el-button>
          <el-button type="primary" size="mini" @click="confirmChangeNum()">确认</el-button>
        </span>
      </el-dialog>
      <!--补发提示框-->
      <el-dialog :visible.sync="reissueDialogVisible" top="45vh" center width="40%">
        <span slot="title" style="font-size: 20px">系统提示</span>
        <div style="text-align: center">
          <span style="font-size: 18px">是否已和供应商确认数量</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" size="mini" @click="reissueDialogVisible = false">尚未</el-button>
          <el-button type="primary" size="mini" @click="confirmReissue()">确认</el-button>
        </span>
      </el-dialog>
      <!--补发提示框-->
      <el-dialog :visible.sync="importExcelDialogVisible" top="30vh" center width="20%">
        <span slot="title" style="font-size: 20px">导入Excel文件数据</span>
        <div style="text-align: center">
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :on-preview="handlePreview"
            multiple
            :limit="1"
            :on-exceed="handleExceed"
            accept=".xls, .xlsx"
            :auto-upload="false"
            :file-list="fileList"
            :on-remove="removeFile"
            :on-change="selectFile"
            :before-upload="beforeUpload"
          >
            <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件!</div>
            <el-button slot="trigger" size="small" type="primary" icon="el-icon-document-add">选择文件</el-button>
          </el-upload>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" size="mini" @click="cancelUpload">取消</el-button>
          <el-button type="primary" size="mini" @click="addImport()">导入</el-button>
        </span>
      </el-dialog>
      <el-dialog width="50%" :visible.sync="Visible" :before-close="handleClose">
        <el-row class="vg_mb_8 vg_mt_24">
          <el-col :md="3"> 材料备注: </el-col>
          <el-col :md="21">
            <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 10 }" placeholder="请输入内容" v-model="remarkFile"> </el-input>
          </el-col>
        </el-row>
        <el-row class="vg_mb_8">
          <el-col :md="24" class="vd_dis_center">
            <el-button type="primary" size="mini" @click="submitClick()">保存</el-button>
          </el-col>
        </el-row>
      </el-dialog>
      <!--		</el-card>-->
      <!--变更记录提示框-->
      <el-dialog title="变更记录" :visible.sync="changeVisible" center width="50%">
        <div class="vg_mb_5" style="text-align: center" v-for="item in recordChangeList" :key="item.puca_chan_id">
          {{ item.puca_chan_date | formatDate }}{{ item.puca_chan_cont }}
        </div>
      </el-dialog>
      <el-dialog title="快递物流信息" :visible.sync="dialogVisible2" width="30%">
        <!--<el-image-->
        <!--	  style="width: 100%; height: 100%"-->
        <!--	  src="https://www.petsprod.com/mesfile/image/pub/kuaidi.png"-->
        <!--	  fit="fill"/>-->
        <el-timeline :reverse="false">
          <el-timeline-item v-for="(activity, index) in expressInfo" :key="index" :timestamp="activity.time">
            {{ activity.context }}
          </el-timeline-item>
        </el-timeline>
        <div slot="footer" class="dialog-footer"></div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import helper from '@assets/js/helper.js';
import { matrAPI } from '@api/modules/matr';
import { BigNumber } from 'bignumber.js';
import textEnlargement from '@/views/component/textEnlargement';
import htmlToExcel from '@/plugins/htmlToExcel';

export default {
  name: 'matrList',
  components: {
    textEnlargement
  },
  data() {
    return {
      visible: true, //dddd
      fileList: [],
      file: null,
      issueDialogVisible: false,
      invalidDialogVisible: false,
      changeNumDialogVisible: false,
      reissueDialogVisible: false,
      importExcelDialogVisible: false,
      searchForm: {
        page_no: 1,
        puca_id: null,
        podr_no: null,
        supp_abbr: null,
        puca_stff_name: null,
        puca_type: null
      },
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      loadingFlag: true,
      timeValue: [],
      statusList: [
        {
          value: 0,
          label: '送货单'
        },
        {
          value: 1,
          label: '补货单'
        }
      ],
      sourceList: [
        {
          value: '0',
          label: '补货单'
        },
        {
          value: '1',
          label: '送货单'
        }
      ],
      Visible: false,
      remarkFile: '',
      transferForm: {},
      pucaMtrbId: 0,
      isFlag: true,
      chooseIndex: 0,
      openFlag: 1, //1：变更，2：补发
      showFlag: true,
      invalidFlag: false,
      textEnlargementString: {
        str: ''
      },
      textEnlargementNum: '',
      changeArrNumn: [],
      changeArrFlag: false,
      changeVisible: false,
      changeList: ['', '', ''],
      recordChangeList: [],
      dialogVisible2: false,
      expressInfo: [],
      userInfo: {},
      isExporting: false
    };
  },
  created() {
    this.userInfo = this.$cookies.get('userInfo');
    this.transferForm = this.$route.query;
    if (this.$route.query.supp_abbr) {
      this.searchForm.supp_abbr = this.$route.query.supp_abbr;
    }
    this.initData();
  },
  watch: {},
  filters: {
    // 时间转换
    formatDate(row) {
      return helper.toTimeDay(row);
    }
  },
  methods: {
    initData() {
      this.loadingFlag = true;
      this.getPucas();
    },
    // 获取材料采购合同list
    getPucas(val) {
      this.searchForm.puca_id = this.$route.query.form_id;
      get(matrAPI.getPucaById, this.searchForm).then(res => {
        if (res.data.code === 0) {
          this.transferForm = res.data.data.form;
          this.tableData = res.data.data.form.puca_mtrb_list;
          if (res.data.data.form.puca_status === 3) {
            this.invalidFlag = true;
          }
          this.totalPage = res.data.data.total;
          this.btn = res.data.data.btn;
          this.tableData.forEach(item => {
            if (this.changeArrFlag) {
              let temp = false;
              this.changeArrNumn.forEach(ite => {
                if (ite === item.puca_mtrb_id) {
                  temp = true;
                }
              });
              if (temp) {
                item.numchaneg = 1;
              } else {
                item.numchaneg = 0;
              }
            } else {
              item.numchaneg = 0;
            }
            item.clickChangeFlag = true;
            item.clickReissueFlag = true;
            item.remarkFlag = false;
            if (item.puca_status === 0) {
              item.changeFlag = false;
              item.reissueFlag = true;
            } else if (item.puca_status === 1) {
              item.changeFlag = false;
              item.reissueFlag = false;
            } else if (item.puca_status === 2) {
              item.changeFlag = true;
              item.reissueFlag = false;
            } else if (item.puca_status === 3) {
              item.changeFlag = true;
              item.reissueFlag = true;
            }
            item.puca_mtrb_num_copy = JSON.parse(JSON.stringify(item.puca_mtrb_num));
            item.puca_mtrb_price_copy = JSON.parse(JSON.stringify(item.puca_mtrb_price));
            item.puca_mtrb_total_copy = JSON.parse(JSON.stringify(item.puca_mtrb_total));
            item.puca_mtrb_total = this.helper.reservedThree(item.puca_mtrb_total);
            item.puca_mtrb_price = this.helper.reservedThree(item.puca_mtrb_price);
          });
          setTimeout(() => {
            this.loadingFlag = false;
          }, 500);
        }
      });
    },
    showDialog(val) {
      if (!val) return this.$message.warning('请填写快递单号!');
      this.dialogVisible2 = true;
      post('/api/kuaidi100/realTimeQueryTrack', { num: val }).then(({ data }) => {
        if (data.status === '200') {
          this.expressInfo = data.data;
        } else {
          this.$message.warning('请检查快递单号!');
        }
      });
    },
    // 添加记录
    addPucaChan() {
      let params = {};
      let changeArr = [];
      this.changeList.forEach(item => {
        if (item) {
          changeArr.push(item);
        }
      });
      if (changeArr.length > 0) {
        params.puca_mtrb_id = this.tableData[this.chooseIndex].puca_mtrb_id;
        params.puca_id = this.tableData[this.chooseIndex].puca_id;
        params.puca_chan_cont = changeArr.join(',');
        params.puca_chan_date = parseInt(new Date().getTime() / 1000);
        post(matrAPI.addPucaChan, params).then(({ data }) => {
          if (data.code === 0) {
            this.changeList = ['', '', ''];
            this.initData();
          }
        });
      } else {
        this.initData();
      }
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        page_no: 1
      };
      this.timeValue = [];
      this.loadingFlag = true;
      this.initData();
    },
    // 打印
    PrintPucaPdfByModrId() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.transferForm.modr_id;
          get(matrAPI.exportPucaPdfByModrId, { modr_id: str })
            .then(res => {
              if (res.data.code === 0) {
                window.open(res.data.data.pdf_url);
                return;
              }
              this.$message.error(res.data.msg);
              this.isLoading = false;
            })
            .catch(res => {
              this.$message.error(res.data.msg);
              this.isLoading = false;
            });
        })
        .catch(() => {});
    },
    // 下载
    exportPucaPdfBy() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.transferForm.modr_id;
          get(matrAPI.exportPucaPdfByModrId, { modr_id: str })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItemCopy(res.data.data.pdf_url, '材料订单.pdf');
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    // 查询方法
    getNow() {
      this.loadingFlag = true;
      this.currentPage = 1;
      this.initData();
    },
    // 分页查询
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.initData();
    },
    // 打开备注弹框
    openRemark(val) {
      this.Visible = true;
      this.remarkFile = val.puca_mtrb_remark;
      this.pucaMtrbId = val.puca_mtrb_id;
    },
    // 保存
    submitClick() {
      if (!this.remarkFile) return this.$message.warning('请填写备注信息');
      this.$confirm('是否保存？', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          post(matrAPI.editPucaMtrbRemark, {
            puca_mtrb_id: this.pucaMtrbId,
            puca_mtrb_remark: this.remarkFile
          }).then(({ data }) => {
            if (data.code === 0) {
              this.$message.success('保存成功');
              this.initData();
              this.Visible = false;
            } else {
              this.$message.error(data.msg);
            }
          });
        })
        .catch(() => {});
    },
    handleClose(done) {
      this.Visible = false;
    },
    // 计算金额
    pucaMtrbTotalChange(val, val1) {
      let totalA = new BigNumber(this.tableData[val].puca_mtrb_num);
      totalA = totalA.times(this.tableData[val].puca_mtrb_price).toNumber();
      this.tableData[val].puca_mtrb_total = totalA;
      console.log('puca_mtrb_num,puca_mtrb_price,puca_mtrb_total', this.tableData[val]);
      if (val1 === 1) {
        this.changeList[0] = '订单数量由' + this.tableData[val].puca_mtrb_num_copy + '变成了' + this.tableData[val].puca_mtrb_num;
      } else {
        this.changeList[1] = '单价由' + this.tableData[val].puca_mtrb_price_copy + '变成了' + this.tableData[val].puca_mtrb_price;
      }
      this.changeList[2] = '金额由' + this.tableData[val].puca_mtrb_total_copy + '变成了' + this.tableData[val].puca_mtrb_total;
      // this.tableData[scope.$index].puca_mtrb_num
    },
    //保存
    sublitRow() {
      if (this.openFlag === 1) {
        this.changeNum();
      } else if (this.openFlag === 2) {
        this.reissue();
      }
    },
    // 下发功能
    issue() {
      if (!this.isFlag) return this.$message.warning('请先保存');
      if (this.multiSelection.length === 0) {
        this.$message.warning('请至少选择一条数据!');
        return;
      }
      // let issueFlag = this.multiSelection.filter(item => {
      // 	return item.puca_status !== 0
      // })
      // if (issueFlag.length > 0) return this.$message.warning("只有下发状态为未下发的才可下发")
      this.issueDialogVisible = true;
    },
    confirmIssue() {
      let list = [];
      console.log('this.multiSelection', this.multiSelection);
      this.multiSelection.forEach(item => {
        if (item.puca_status === 0) {
          list.push(item);
        }
      });
      if (list.length > 0) {
        let idsArr = list.map(({ puca_mtrb_id }) => Object.values({ puca_mtrb_id }).toString());
        // 批量下发
        post(matrAPI.addDentByPucaMtrbIds, { puca_mtrb_id_list: idsArr }).then(({ data }) => {
          if (data.code === 0) {
            this.$message.success('下发成功!');
            this.isFlag = true;
            this.initData();
          }
        });
      } else {
        this.$message.warning('已全部下发');
      }
      this.issueDialogVisible = false;
    },
    removeFile() {
      // 单选暂定清空
      this.fileList = [];
      this.file = null;
    },
    selectFile(file, fileList) {
      this.fileList = fileList;
      this.file = file.raw;
    },
    cancelUpload() {
      this.importExcelDialogVisible = false;
      this.fileList = [];
    },
    // 新增导入---feature 删除 直接跳编辑
    addImport() {
      //上传内容
      let formData = new FormData();
      formData.append('excelFile', this.file);
      post(matrAPI.import_excel, formData).then(({ data }) => {
        if (data.code === 0) {
          this.getPucas();
        }
      });
      this.importExcelDialogVisible = false;
    },
    // 打印上传内容
    handlePreview(file) {},
    // 回复上传内容
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 限制上传前文件为.xls, .xlsx
    beforeUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      const whiteList = ['xls', 'xlsx'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传文件只能是xls、xlsx格式');
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 5MB');
        return false;
      }
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 查询快递物流单号详情
    logDetail() {},
    // 打开变更记录
    changeRecord(val, scope) {
      if (this.isFlag) {
        this.$set(this.tableData[val], 'numchaneg', 0);
        if (this.changeArrNumn.indexOf(this.tableData[val].puca_mtrb_id) !== -1) {
          this.changeArrNumn.splice(this.changeArrNumn.indexOf(this.tableData[val].puca_mtrb_id), 1);
        }
        if (this.changeArrNumn.length === 0) {
          this.changeArrFlag = false;
        }
        matrAPI.editPucaMtrbIsUpdate0({ puca_mtrb_id: scope.row.puca_mtrb_id }).then(({ data }) => {
          this.tableData[val].is_update = 0;
        });
        this.changeVisible = true;
        this.recordChangeList = scope.row.puca_chan_list;
      } else {
        this.$message.warning('请先保存');
      }
    },
    // 打开更改变更数量
    changeNumCopy(val) {
      if (this.isFlag) {
        this.showFlag = false;
        let obj = JSON.parse(JSON.stringify(this.tableData[val]));
        obj.clickChangeFlag = false;
        this.$set(this.tableData, val, obj);
        this.isFlag = false;
        this.chooseIndex = val;
        this.openFlag = 1;
        this.showFlag = true;
      } else {
        this.$message.warning('请先保存');
      }
    },
    // 变更订单数量，详情只有点了变更才有变更一栏
    changeNum() {
      this.changeNumDialogVisible = true;
    },
    confirmChangeNum() {
      post(matrAPI.editPucaMtrbCnum, {
        puca_mtrb_id: this.tableData[this.chooseIndex].puca_mtrb_id,
        puca_mtrb_cnum: Number(this.tableData[this.chooseIndex].puca_mtrb_cnum),
        puca_mtrb_num: Number(this.tableData[this.chooseIndex].puca_mtrb_num),
        puca_mtrb_price: Number(this.tableData[this.chooseIndex].puca_mtrb_price),
        puca_mtrb_total: this.tableData[this.chooseIndex].puca_mtrb_total
      }).then(({ data }) => {
        if (data.code === 0) {
          this.changeArrFlag = true;
          let temp = this.changeArrNumn;
          temp.push(this.tableData[this.chooseIndex].puca_mtrb_id);
          this.changeArrNumn = temp;
          this.$message.success('保存成功');
          this.isFlag = true;
          this.addPucaChan();
        } else {
          this.$message.error(data.msg);
        }
      });
      this.changeNumDialogVisible = false;
    },
    // 补货单新增选择送货单,除了数量和变更数量（栏目没有），补发的一旦保存即可生效
    reissue() {
      this.reissueDialogVisible = true;
    },
    // 新增补发
    reissueCopy(val) {
      if (this.isFlag) {
        this.showFlag = false;
        let obj = JSON.parse(JSON.stringify(this.tableData[val]));
        // this.$set(this.tableData,val,obj)
        obj.clickReissueFlag = true;
        obj.clickChangeFlag = false;
        obj.puca_mtrb_num = null;
        obj.puca_type = 1;
        obj.puca_mtrb_cnum = 0;
        obj.puca_mtrb_bnum = 0;
        obj.puca_status = 0;
        obj.remarkFlag = true;
        obj.puca_mtrb_total = null;
        obj.puca_mtrb_name = null;
        this.tableData.splice(val + 1, 0, obj);
        this.isFlag = false;
        this.chooseIndex = val;
        this.openFlag = 2;
        this.showFlag = true;
        this.tableData.forEach((item, index) => {
          item.key = Number(index);
        });
      } else {
        this.$message.warning('请先保存');
      }
    },
    // 补货单新增选择送货单,除了数量和变更数量（栏目没有），补发的一旦保存即可生效
    confirmReissue() {
      if (!this.tableData[this.tableData.length - 1].puca_mtrb_num) return this.$message.warning('请把数量补充完整');
      let params = this.tableData[this.chooseIndex + 1];
      params.puca_mtrb_num = Number(params.puca_mtrb_num);
      params.puca_mtrb_price = Number(params.puca_mtrb_price);
      params.puca_mtrb_total = Number(params.puca_mtrb_total);
      post(matrAPI.addPucaMtrb, params).then(({ data }) => {
        if (data.code === 0) {
          this.$message.success('保存成功');
          this.isFlag = true;
          this.initData();
        }
      });
      this.reissueDialogVisible = false;
    },
    makeInvalidOrder() {
      if (!this.isFlag) return this.$message.warning('请先保存');
      if (this.multiSelection.length === 0) {
        this.$message.warning('请至少选择一条数据!');
        return;
      }
      if (this.multiSelection.filter(x => x.puca_type === 0).length > 0) {
        this.$message.warning('送货单不可失效!');
        return;
      }
      this.invalidDialogVisible = true;
    },
    confirmMakeInvalidOrder() {
      let idsArr = this.multiSelection.map(({ puca_mtrb_id }) => Object.values({ puca_mtrb_id }).toString());
      // 批量下发
      post(matrAPI.pucaInvalidByIds, { puca_mtrb_id_list: idsArr }).then(({ data }) => {
        if (data.code === 0) {
          this.$message.success('已失效!');
          this.initData();
          this.invalidDialogVisible = false;
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    textEnlargementChange(val) {
      this.tableData[this.textEnlargementNum].puca_mtrb_remark = val;
    },
    // 框显示效果
    textEnlargementShow(index) {
      this.textEnlargementNum = index;
      this.textEnlargementString.str = this.tableData[index].puca_mtrb_remark;
    },
    // 导出excel
    exportToExcel() {
      this.loadingFlag = true;
      this.isExporting = true;
      setTimeout(() => {
        htmlToExcel.getExcel('#matrExcel', '材料明细' + helper.toTimeDay(new Date().getTime() / 1000));
      }, 1000);
      setTimeout(() => {
        this.loadingFlag = false;
        this.isExporting = false;
      }, 2000);
    }
  }
};
</script>

<style scoped lang="scss">
.vd_button_group {
  width: 100vw;
  position: relative;
}
.vd_txta {
  position: absolute;
  left: 35%;
}
::v-deep .vd_tenpd {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vd_img {
  margin-top: 5px;
  width: 40px;
}
.vd_notpass {
  color: rgb(187, 187, 187);
}
.vd_pass {
  color: #188dff;
}
.vd_complate {
  color: #36ab60;
}
.vd_disable {
  color: #d81e06;
}
.vd_wei {
  color: #ff8511;
}
.vd_div {
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}
.vd_dis {
  display: flex;
}
.vd_elimg {
  margin-top: 5px;
  width: 60px;
  height: 60px;
}
.vg_hover_icon:hover {
  background-image: url('~@/assets/image/wl07.png');
  background-repeat: no-repeat;
  left: -10px;
  background-position: center left 0%;
  background-size: 12px 12px;
}
.el-icon-user-solid {
  color: #34b7f1;
  font-size: 12px;
}
.el-icon-phone-outline {
  color: #67c23a;
  font-size: 12px;
}
.upload-demo {
  margin-right: 10px;
  ::v-deep.el-upload--text {
    border: none;
    width: auto;
    height: 100%;
    border-radius: 0;
  }
  ::v-deep .el-upload-list .el-upload-list--text {
    display: none;
  }
  ::v-deep .el-list-leave-active {
    transition: none;
  }
  ::v-deep .el-list-leave-to {
    transition: none;
  }
}
.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}
.vd_dis_center {
  display: flex;
  justify-content: center;
}
.vd_posi {
  position: absolute;
  top: 0;
  right: -5px;
  border-radius: 50%;
  background-color: #d81e06;
  width: 10px;
  height: 10px;
}
.vd_posi_c {
  position: relative;
}
.vd_cd81e06 {
  color: #d81e06;
}
.vd_c4dbf2a {
  color: #4dbf2a;
}

.changeRecords {
  height: 40px;
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formItem {
  ::v-deep .el-form-item {
    width: 100%;
  }
  ::v-deep .el-form-item__content {
    width: 80%;
  }
}
</style>
